/* Grid/Reset Overrides */
button:active, button:focus, .bbutton:active, .bbutton:focus, select:focus, select:active {outline: none;}button::-moz-focus-inner, input[type="button"]::-moz-focus-inner, input[type="submit"]::-moz-focus-inner, input[type="reset"]::-moz-focus-inner, select::-moz-focus-inner, option::-moz-focus-inner {padding: 0 !important; outline:none; border: 0 none !important;}select:-moz-focusring {color: transparent;text-shadow: 0 0 0 #000;}
p, ul, ol {margin-bottom: 1em;}b, strong, .bold, .strong {font-family: "Helvetica Neue Bold"; font-weight: normal;}i, em {font-style: italic;}p:empty {display: none;}img, iframe, object, embed, video {max-width: 100%;}
ul:last-child, ol:last-child, p:last-child, h1:last-child, h2:last-child, h3:last-child, h4:last-child, h5:last-child, h6:last-child {margin-bottom: 0;}

/* Custom Styles */
body {-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;}
.uppercase {text-transform: uppercase;}
.clear {clear: both;}
.section {clear: both; float: left; width: 100%;}
.subsection  {clear: both; float: left; width: 100%;}
.ir {float: left;width: 100%;font-size: 0;line-height: 0;text-indent: -9999px;overflow: hidden;background-color: transparent;background-position: left top;background-repeat: no-repeat;}
.href {cursor: pointer;}
.hidden, .hide, .mobile-only, .mobileonly, .phone-only, .phoneonly  {display: none;}
.alignvertical{position: absolute;top: 50%;transform: translateY(-50%);}
textarea {resize: vertical;}
.noselect {-webkit-touch-callout: none;-webkit-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;}
.no-margin {margin: 0;}
*, *:active, *:focus {outline:none;}