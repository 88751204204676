@import url("//fast.fonts.net/t/1.css?apiType=css&projectid=94998cd1-8136-46c9-a2fe-6ae8c64fa04e");
@font-face{
   font-family: "Helvetica Neue Regular";
   src:url("../static/fonts/706ec8e5-fe4a-4518-91a6-7aba4d3d333f.eot?#iefix");
   src:url("../static/fonts/706ec8e5-fe4a-4518-91a6-7aba4d3d333f.eot?#iefix") format("eot"),
   url("../static/fonts/dc50c02f-3f77-4e75-b89c-e3f9bb4752e6.woff2") format("woff2"),
   url("../static/fonts/ce6f5b38-1cb5-4a27-8182-583aa68b2436.woff") format("woff"),
   url("../static/fonts/ff06cde7-e06a-4cf0-af4d-5b2f737bf544.ttf") format("truetype");
}
@font-face{
   font-family: "Helvetica Neue Bold";
   src:url("../static/fonts/a95d2895-fa71-4ce7-bc1e-631169924be3.eot?#iefix");
   src:url("../static/fonts/a95d2895-fa71-4ce7-bc1e-631169924be3.eot?#iefix") format("eot"),
   url("../static/fonts/6dfa3ef5-ebc5-4d23-a631-b3d746c2ad39.woff2") format("woff2"),
   url("../static/fonts/588c9ba8-1d28-4ad6-bf18-7128152d84c4.woff") format("woff"),
   url("../static/fonts/55334dfb-9c72-4147-8de0-21239f17ff25.ttf") format("truetype");
}