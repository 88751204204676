@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/bootstrap";
@import "~rc-tooltip/assets/bootstrap_white.css";
@import "~react-phone-number-input/style.css";
@import "./vars";
@import "./components/Overrides";
@import "./components/Spinner";
@import "./webfonts";

body {
   color: $text;
   font-family: "Helvetica Neue Regular", Arial, Helvetica, sans-serif;
   background: $body_background;
}

a {
   color: $blue;
   text-decoration: none;
   &.arrow-link {
      font-family: "Helvetica Neue Bold";
      &:after {
         content: '';
         display: inline-block;
         width: 6px;
         height: 12px;
         margin-left: 5px;
         vertical-align: middle;
         background: transparent url("../static/img/forward-carrat-red.svg") no-repeat left top / contain;
      }
   }
}
a:hover {
   color: $text;
   text-decoration: none;
}
input,
select,
textarea,
.fieldset select {
   padding: 5px 8px;
   color: #1c1c1b;
   font-size: 14px;
   line-height: 19px;
   border: 1px solid $blue;
   background: #ffffff;
   border-radius: 0;
   max-width: 100%;
}
input[type="image"] {
   padding: 0;
   border-radius: 0;
   border: none;
   width: auto;
   background: 0;
}
input[type="submit"] {
   color: #fff;
   border: none;
   padding: 5px 8px;
   border-radius: 0;
   font-size: 14px;
   line-height: 19px;
   -webkit-appearance: none;
   transition: all 500ms ease;
   cursor: pointer;
   background: #333;
}
input[type="submit"]:hover {
   -webkit-appearance: none;
   background: #000;
}
input[type="text"]:read-only, .fancy:disabled {
   background: transparent;
   &:focus {
      border-bottom: 1px solid $border;
   }
}
h1 {
   font-family: "Helvetica Neue Bold", Arial, Helvetica, sans-serif;
   font-size: 26px;
   font-weight: normal;
   color: $blue;
   margin-bottom: 25px;
   .header-center & {
      font-size: 18px;
      margin: 0;
   }
}
h2 {
   font-family: "Helvetica Neue Bold", Arial, Helvetica, sans-serif;
   font-size: 18px;
   font-weight: normal;
   color: #111;
   margin-bottom: 15px;
}
h2 span {
   font-weight: 300;
}
h3 {
   font-family: "Helvetica Neue Bold", Arial, Helvetica, sans-serif;
   font-size: 16px;
   color: $blue;
   font-weight: normal;
   margin-bottom: 10px;
}
h4 {
   font-family: "Helvetica Neue Bold", Arial, Helvetica, sans-serif;
   font-size: 14px;
   font-weight: normal;
   color: $blue;
   margin-bottom: 7px;
}
.font-bold {
   font-family: "Helvetica Neue Bold", Arial, Helvetica, sans-serif;
}
.fs-11 {
   font-size: 11px;
}
.fs-14 {
   font-size: 14px;
}
.lh-18 {
   line-height: 18px;
}
.lh-22 {
   line-height: 22px;
}
.lh-28 {
   line-height: 28px;
}
.p-15 {
   padding: 15px !important;
}
.dark {color: $black;}
.color-white,
.color-white h1,
.color-white h2,
.color-white h3,
.color-white h4,
.color-white h5,
.color-white h6,
.white-headline,
.white-headline h1,
.white-headline h2,
.white-headline h3,
.white-headline h4,
.white-headline h5,
.white-headline h6 {
   color: #fff;
}
.color-red {color: $red;}
.color-blue {
   color: $blue;
}
.color-body {
   color: $text;
}
.green {color: $green;}
.form-group {
   margin-bottom: 1rem;
}
.w-100 {
   width: 100% !important;
}
.mt-150 {
   margin-top: 150px;
}
.credit-card {
   background-color: $white;
   border: 1px solid #707070;
   border-radius: 5px;
   width: 42px;
   height: 28px;
   display: flex;
   justify-content: center;
}
.total-price {
   font-size: 18px;
}
.external-payment-method {
   background-color: $white;
   border: 1px solid $border_dark;
   border-radius: 3px;
   width: 100%;
}
.page-account_recovery_phone, .page-account_recovery_verify {
   h1 {
      letter-spacing: -0.025em;
   }
   .floating-label {
      .inline-label-wrapper {
         top: 0;
      }
   }
}
.page-account_recovery_confirm {
   .btn-primary {
      font-size: 16px;
   }
}
.main-container.page-payment_review {
   padding-top: 58px;
   h1 {
      margin: 30px 0;
   }
}

.error-icon {
   width: 26px;
}
.warning-icon {
   width: 26px;
}
.form-group.has-error .field-error {
   display: block;
}
.form-group.has-error .form-control {
   border-color: #e71921;
}
.show-required {
   .is-required:before {
      content: "* ";
      //color: #e71921;
   }
}
.pre-form-error {
   color: #e71921;
   margin-bottom: 2em;
}

input:-webkit-autofill {
   -webkit-box-shadow: 0 0 0 30px white inset;
   box-shadow: 0 0 0 30px white inset;
}

.form-control {
   display: block;
   width: 100%;
   padding: 10px;
   height: auto;
   font-size: 14px;
   line-height: 1.5;
   color: #595959;
   background-color: #fff;
   background-clip: padding-box;
   border: 1px solid #336;
   transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
   .has-uncover-button & {padding-right: 85px;}
}

.form-control:focus {
   color: #595959;
   background-color: #fff;
   border-color: #6666b3;
   outline: 0;
   box-shadow: 0 0 0 2px #336;
}

.form-control-read-only {
   border: none;
   padding: 10px 0;
   margin: 0;
   background: 0;
}

.note {
   font-size: 85%;
   line-height: 16px;
}

.btn,
.usps-segmented-control .usps-radio-button {
   display: inline-block;
   font-family: "Helvetica Neue Bold", Helvetica, Arial, sans-serif;
   color: #595959;
   text-align: center;
   vertical-align: middle;
   -webkit-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;
   background-color: transparent;
   border: 1px solid transparent;
   border-top-color: transparent;
   border-right-color: transparent;
   border-bottom-color: transparent;
   border-left-color: transparent;
   padding: 0.65rem 1.3rem;
   font-size: 0.875rem;
   line-height: 1.5;
   border-radius: .2rem;
   transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn-outline-primary,
.btn-add-item,
.usps-segmented-control .usps-radio-button {
   color: #336;
   border-color: #336;
}
.btn-edit {
   font-size: 14px;
   color: $blue;
   padding: 12px;
   border: none;
   outline: none;
   background-color: $background_tertiary;
}
.btn-add-item {
   padding: 0.45rem 1.3rem;
}
.btn-add-item * {vertical-align: middle;}
.btn {
   cursor: pointer;
}
.btn.btn-block {
   display: block;
}
.btn-text {
   appearance: none;
   border: none;
   background: transparent;
   color: #336;
   cursor: pointer;
   padding: 0.65rem 1.3rem;
}
.btn-text:hover {
   color: #595959;
}
.btn-text:active, .btn-text:focus {
   outline: none;
}
.btn-chromeless, .btn-accordion {
   appearance: none;
   border: none;
   background: transparent;
   cursor: pointer;
   padding: 0;
   margin: 0;
   color: $blue;
   outline: none;
   &:active, &:focus {
      outline: none;
      border: none;
      box-shadow: none;
   }
}

.btn-accordion {
   font-size: 14px;
   &:after {
      display: inline-block;
      content: '';
      width: 10px;
      height: 5px;
      margin-left: 10px;
      transition: all .2s cubic-bezier(0,.75,.05,.98);
      background-size: contain;
      background-image: url("../static/img/accordion-carrat.svg");
      background-repeat: no-repeat;
   }
}

.btn-primary {
   background-color: $blue;
   border-color: $blue;
   color: #fff;
}
.btn-primary:hover, .btn-outline-primary:hover {
   background-color: $background_dark;
   border-color: $background_dark;
   color: #336;
}
body.has-touch .btn-primary:hover {
   background-color: #336;
   border-color: #336;
   color: #fff;
}
.btn-primary.disabled, .btn-primary:disabled {
   background-color: $background_dark;
   color: #999;
   border-color: $background_dark;
}
.btn-cancel {
   font-size: 0.85rem;
   color: #336;
   font-weight: normal;
   background-color: transparent;
}
.btn-outline-light:hover {
   color: #336;
}
.btn-white {
   background-color: transparent;
   border-color: #fff;
   color: #fff;
   &:hover {
      color: #336;
      background-color: #fff;
   }
}

.btn-next {
   border-radius: 28px;
   padding: 15px;
   width: 100px;
   &:after {
      content: '';
      float: right;
      width: 9px;
      height: 15px;
      margin-left: 10px;
      margin-top: 4px;
      background: transparent url("../static/img/forward-carrat.svg") no-repeat left top;
   }
}
.btn-primary.focus,.btn-primary:focus{box-shadow:0 0 0 .2rem rgba(82,82,125,.5)}
.btn-primary:not(:disabled):not(.disabled).active:focus,.btn-primary:not(:disabled):not(.disabled):active:focus,.show>.btn-primary.dropdown-toggle:focus{box-shadow:0 0 0 .2rem rgba(82,82,125,.5)}
.btn-primary:not(:disabled):not(.disabled).active,.btn-primary:not(:disabled):not(.disabled):active,.show>.btn-primary.dropdown-toggle{color:#fff;background-color:#222244;border-color:#005cbf}
a.bg-primary:focus,a.bg-primary:hover,button.bg-primary:focus,button.bg-primary:hover{background-color:#222244!important}

.btn-uncover {
   float: right;
   position: absolute;
   top: 12px;
   right: 0;
   font-size: 12px;
   text-transform: uppercase;
   span {display: inline-block; padding-top: 1px; padding-right: 5px;}
   &:after {
      content: '';
      float: right;
      width: 20px;
      height: 20px;
      background: transparent url("../static/img/btn-uncover.svg") no-repeat center center / contain;
   }
   &.active:after {
      background: transparent url("../static/img/btn-uncover-active.svg") no-repeat center center / contain;
   }
}

#app-form {padding: 20px 0;}

.section-row {
   padding-top: 15px;
   padding-bottom: 15px;
}
.sep {
   border-top: 1px solid $border;
   padding-top: 30px;
}
.sep-above {
   border-top: 1px solid $border;
}
.sep-below {
   border-bottom: 1px solid $border;
}
.section-row:first-child {margin-top: 0;}
label.error {
   font-size: 85%;
   color: #e71921;
}

.field-wrapper {position: relative;}
.field-icon {
   position: absolute;
   z-index: 1;
   top: 11px;
   color: $blue;
}
.field-prefix {
   left: 0;
   padding-left: 8px;
}
.field-suffix {
   right: 0;
   padding-right: 8px;
}

.field-wrapper .form-control {position: relative; background-color: transparent; z-index: 2;}


.form-button-container {
   float: right;
   margin-right: 30px;
}
   .form-button-container:first-child {margin-right: 0;}
.radio-button-inline {
   display: inline-block;
   margin-left: 30px;
}
.radio-button-inline:first-child {margin-left: 0;}
.btn-trigger {
   position: relative;
   padding-right: 20px;
}
.btn-trigger:after {
   background-image: url("../static/img/accordion-carrat.svg");
   background-repeat: no-repeat;
   content: "";
   display: block;
   position: absolute;
   right: 0;
   top: 50%;
   margin-top: -4px;
   width: 15px;
   height: 8px;
   transition: all .2s cubic-bezier(0,.75,.05,.98);
}
.btn-trigger.active:after, .btn-accordion.btn-accordion-open:after {
   -webkit-transform: rotate(180deg);
   transform: rotate(180deg);
}

#main {
   padding: 0 0 25px;
}

input.simple, select.simple, select.fancy {
   display: block;
   width: 100%;
   color: $black;
   padding: 5px;
   border-top: none;
   border-left: none;
   border-right: none;
   border-bottom: 1px solid $border;
   &:focus, &:active {
      border-color: $blue;
   }
   .has-uncover-button & {padding-right: 75px;}
}

div.field-error {
   color: $red;
   font-size: 75%;
}

.floating-label {
   position:relative;
   margin-bottom:0;
   text-align: left;
   br {display: none;}
   input, textarea, select {
      height: 2.7rem;
      &:active:not(:placeholder-shown) ~ .inline-label-wrapper, &:focus:not(:placeholder-shown) ~ .inline-label-wrapper {
         color: $blue;
         font-family: "Helvetica Neue Bold";
      }
      border-top: none;
      border-right: none;
      border-left: none;
   }
   &.select-has-value select {
      &:active, &:focus {
         & ~ .inline-label-wrapper {font-family: "Helvetica Neue Bold"; color: $blue;}
      }
   }
   label{
      display: block;
      padding: 0;
      margin: 0;
   }
   .inline-label-wrapper{
      position:absolute;
      top:10px;
      left:0;
      display:block;
      font-size: 14px;
      width:100%;
      padding: 10px 5px 5px;
      margin-bottom:0;
      line-height:1.5;
      color:$placeholder;
      pointer-events:none;
      cursor:text;
      border:1px solid transparent;
      transition:all .1s ease-in-out
   }
   ::-webkit-input-placeholder{color:transparent}
   :-ms-input-placeholder{color:transparent}
   ::-ms-input-placeholder{color:transparent}
   ::-moz-placeholder{color:transparent}
   ::placeholder{color:transparent}
   input:not(:placeholder-shown), textarea:not(:placeholder-shown), &.select-has-value select, &.filled input {
      padding-top:1.25rem;
      padding-bottom:.25rem
   }
   input:not(:placeholder-shown)~.inline-label-wrapper, textarea:not(:placeholder-shown)~.inline-label-wrapper, &.select-has-value .inline-label-wrapper, &.filled .inline-label-wrapper{
      padding-top:0;
      padding-bottom:.25rem;
      font-size:12px;
      color:$text;
      .form-group.has-error & {color: $red;}
   }
   &.form-group.has-error {
      input.simple, select.simple, select.fancy {border-color: $red;}
      .field-error {display: none;}
   }
   &.filled .inline-label-wrapper {color: $blue;}
   &.phone-input .inline-label-wrapper {padding-left: 30px;}
}
.has-error.floating-label {
   input, textarea, select {
      &:not(:placeholder-shown) ~ .inline-label-wrapper label {
         color: $red;
      }
      &:active:not(:placeholder-shown) ~ .inline-label-wrapper label, &:focus:not(:placeholder-shown) ~ .inline-label-wrapper label {
         font-family: "Helvetica Neue Bold";
      }
   }
}
.simple-select, .fancy-select {
   select {padding: 12px 0;}
   &.form-group.has-error {
      input.simple {border-color: $red;}
      select.simple {border-color: $red;}
      select.fancy {border-color: $red;}
      .field-error {display: none;}
   }
}

select.fancy {
   -webkit-appearance: none;
   -moz-appearance: none;
   appearance: none;
   text-indent: 1px;
   text-overflow: '';
   background-size: 20px 10px;
   background-image: url("../static/img/accordion-carrat.svg");
   background-position: right 10px center;
   background-repeat: no-repeat;
   &::-ms-expand {
      display: none;
   }
   &:active, &:focus {outline: none !important;}
}

.button-bar {
   padding: 10px 0;
   .btn-primary {
      float: right;
   }
}

.form-box {
   margin-top: 25px;
   box-shadow: 0px 3px 4px 0px rgba(85,85,128,0.1);
   background: $white;
   &.disabled-form {
      background: transparent;
      box-shadow: none;
      .inline-label-wrapper {
         color: $text !important;
         font-family: "Helvetica Neue Regular", Arial, Helvetica, sans-serif !important;
      }
   }
}
.combo-box {
   margin-top: 25px;
   box-shadow: 0px 3px 4px 0px rgba(85,85,128,0.1);
   background: $white;
}
.text-box {
   margin-top: 25px;
   box-shadow: 0px 3px 4px 0px rgba(85,85,128,0.1);
   background: $white;
   & > .col-12 {
      padding: 15px;
   }
}

.progress-bar-row {
   position: relative;
   z-index: 100;
   margin-bottom: 5px;
   .col-12 {
      padding: 0;
   }
   .progress-bar-container {
      height: 5px;
      border-top: 1px solid $border_dark;
      background: $border;
      .progress-bar-indicator {
         float: left;
         height: 100%;
         background: $green;
         transition: all 500ms ease;
         border-radius: 0 4px 4px 0;
      }
   }
}

.address-icon {
   float: left;
   width: 45px;
   margin-top: 10px;
   padding-right: 15px;
}
.address-data {
   float: left;
   width: calc(100% - 45px);
   font-size: 16px;
   line-height: 20px;
}
.address-data-idguest {
   h3 {
      letter-spacing: -0.025em;
   }
   p {
      font-size: 14px;
      color: $black;
   }
}

.congrats-inner {
   padding: 50px 0;
}
.overhead {
   position: fixed;
   left: 0;
   top: 0;
   z-index: 99;
   background: $body_background;
   .is-ios & {position:device-fixed; transform: translate3d(0,0,0);}
}

.main-container {padding-top: 100px;}
#header {padding: 15px 0; border-bottom: 1px solid $border; background: $white;}
#logo {
   display: inline-block;
   vertical-align: middle;
   margin-right: 10px;
   padding-right: 10px;
   border-right: 1px solid $border;
   img {min-height: 22px;}
   & + h1 {display: inline-block; vertical-align: middle; letter-spacing: -0.025em;}
}

.header-center {
   font-size: 10px;
   color: $blue;
   img {
      vertical-align: middle;
      max-height: 12px;
   }
   span {
      vertical-align: middle;
      margin-left: 5px;
   }
}

.PhoneInputCountryIconImg {display: block;}
.react-phone-number-input__input, .react-phone-number-input__country {border-bottom: 1px solid $border;}
.react-phone-number-input__input:focus {border-color: $blue;}
.PhoneInputCountrySelectArrow {display: none;}
.PhoneInputCountryIcon--border {border: none; box-shadow: none; background: 0;}

.simple-tooltip {
   position: absolute;
   bottom: calc(100% + 10px);
   width: 100%;
   padding: 10px;
   border-radius: 7px;
   background: $white;
   font-size: 14px;
   -webkit-box-shadow: 0px 0px 4px 0px #ccc;
   box-shadow: 0px 0px 4px 0px #ccc;
   z-index: 2;
   &:after {
      content: '▼';
      position: absolute;
      font-size: 32px;
      top: calc(100% - 20px);
      left: 10px;
      width: 0;
      height: 0;
      color: $white;
      box-shadow: 16px 26px 8px 6px #ccc;
   }
   &.warning {
      padding-left: 45px;
      background: $white url("../static/img/warning.svg") no-repeat 15px center;
   }
}

.error-bubble {
   position: absolute;
   top: calc(100% + 10px);
   color: $red;
   font-family: "Helvetica Neue Bold";
   width: 100%;
   padding: 10px 10px 10px 30px;
   border-radius: 7px;
   background: $white url("../static/img/error.svg") no-repeat 10px center;
   font-size: 14px;
   -webkit-box-shadow: 0px 0px 4px 0px #ccc;
   box-shadow: 0px 0px 4px 0px #ccc;
   z-index: 2;
   &.password-bubble {font-size: 10px; padding-left: 10px; color: $black; font-family: "Helvetica Neue Regular"; background: $white;}
   ul {
      list-style: circle;
      padding: 0 0 0 28px;
      margin: 0;
      font-size: 24px;
      line-height: 20px;
      li {
         position: relative;
         span {
            font-size: 11px;
            line-height: 20px;
            position: relative;
            left: -8px;
            top: -4px;
         }
      }
   }
   &:after {
      content: '▲';
      font-size: 32px;
      position: absolute;
      top: -30px;
      left: 10px;
      width: 0;
      height: 0;
      color: $white;
      box-shadow: 16px 26px 8px 6px #ccc;
   }
   &.error-bubble-top {
      top: auto;
      bottom: calc(100% + 10px);
      &:after {
         content: '▼';
         font-size: 32px;
         position: absolute;
         top: calc(100% - 20px);
         left: 10px;
         width: 0;
         height: 0;
         color: $white;
         box-shadow: 16px 26px 8px 6px #ccc;
      }
   }
}

.label-checkbox {
   float: right;
   width: 30px;
   height: 30px;
   border-radius: 3px;
   border: 1px solid $blue;
   transition: all 250ms ease;
   background-color: $white;
   background-position: center center;
   background-size: 0 0;
   background-repeat: no-repeat;
   :checked + & {
      background-color: $blue;
      background-size: 15px 12px;
      background-image: url("../static/img/check-white.svg");
   }
}

.custom-radio {display: block; margin: 0;}

.radio-row {
   padding-top: 15px;
   padding-bottom: 15px;
   border-top: 1px solid $border;
   &:first-child {
      border-top: none;
   }
}

label.custom-checkbox {line-height: normal;}label.custom-checkbox input {display: none;}label.custom-checkbox span {display: block; position: relative;}label.custom-checkbox span:before {content: '';display: inline-block;vertical-align: top;width: 18px;height: 18px;border: 1px solid #999999;margin-right: 5px;background: #ffffff;border-radius: 2px;}label.custom-checkbox input + span:after {content: "\f00c"; font-weight: 900; font-family: Font Awesome\ 5 Free; position: absolute;top: 2px;left: 3px;font-size: 12px;}label.custom-checkbox input:not(:checked) + span:after {content: '';}
label.custom-radio {display: block;-webkit-touch-callout: none;-webkit-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;}
label.custom-radio input {display: none;}

label.custom-radio span.faux-radio {
   display: block;
   width: 32px;
   height: 32px;
   border-radius: 50%;
   border: 2px solid $blue;
   margin: 0 auto;
}
label.custom-radio input:checked + span span {
   display: block;
   width: 18px;
   height: 18px;
   border-radius: 50%;
   margin: 5px;
   background: $blue;
}

.fine-print {font-size: 12px;}

.checkbox-field label {display: block;}

.warning-icon-large {
   min-height: 27px;
}

.forward-carrat-blue {max-height: 18px;}

.row-long-link {
   color: $black;
   padding: 20px 0;
   font-size: 16px;
   border-top: 1px solid $border;
   .row-long-link-container:first-child & {border-top: none;}
}

.img-next {max-height: 37px;}

@media only screen and (max-width: 991px) {
   html {
      -webkit-text-size-adjust: 100%;
   }
   .mobile-only,
   .mobileonly {
      display: block;
   }
   .mobilehide,
   .mobile-hide {
      display: none !important;
   }
   .main-container {
      padding-bottom: 150px;
   }
   .button-bar {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 15px;
      z-index: 10;
      .button-box-shadow & {
         box-shadow: 0px -3px 4px -3px rgba(85,85,128,0.1);
         background: $white;
         button {
            float: none;
            display: block;
            width: 100%;
            text-align: center;
            margin-top: 10px;
            &:first-child {margin-top: 0;}
         }
      }
   }
   .congrats {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: $body_background;
   }
   .congrats-inner {
      position: absolute;
      top: calc(50% - 32px);
      left: 0;
      width: 100%;
      transform: translateY(-50%);
   }
   .container {width: 100%; max-width: 100%;}
   .note {font-size: 12px;}
}
@media only screen and (max-width: 575px) {
   .btn-save-profile {display: block; width: 100%; text-align: center;}
}
@media only screen and (max-width: 479px) {
   .text-box, .form-box, .combo-box {font-size: 13px;}
   .address-icon {margin-top: 5px;}
}
@media only screen and (max-width: 359px) {
   .text-box {font-size: 12px;}
   .address-icon {width: 35px; margin-top: 10px;}
   .address-data {width: calc(100% - 35px);}
}
@media only screen and (max-height: 359px) {
   .congrats {position: static;}
   .congrats-inner {position: static; transform: none;}
}
@import "./ie";