/* IE10+ CSS styles */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
   .floating-label {
      .inline-label-wrapper {display: none;}
   }
}

/* Edge 13/14 Styles */
@supports (-ms-ime-align: auto) {
   .floating-label {
      .inline-label-wrapper {display: none;}
   }
}