$spinner-width: 35px;
$spinner-stroke-width: 6px;
.spinner {
   display: block;
   position: relative;
   margin: 0 auto;
   width: $spinner-width;
   &.inline-after {
      display: inline-block;
      margin-left: 2em;
      vertical-align: middle;
   }
   &:before {
      content: "";
      display: block;
      padding-top: 100%;
   }
}

.spinner-canvas {
   animation: spinner-rotate 2s linear infinite;
   height: 100%;
   transform-origin: center center;
   width: 100%;
   position: absolute;
   top: 0;
   bottom: 0;
   left: 0;
   right: 0;
   margin: auto;
}

.spinner-path {
   stroke-dasharray: 1, 200;
   stroke-dashoffset: 0;
   animation: spinner-dash 1.5s ease-in-out infinite;
   stroke: $blue;
   stroke-width: $spinner-stroke-width;
   stroke-linecap: round;
}
@keyframes spinner-rotate {
   100% {
      transform: rotate(360deg);
   }
}

@keyframes spinner-dash {
   0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
   }
   50% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -35px;
   }
   100% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -124px;
   }
}
